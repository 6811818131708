<template>
    <div class="sticky-top-bar row">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-4 sticky-left-col">
                    <div class="row">
                        <!-- menu sticky bar  -->
                        <sticky-menu></sticky-menu>
                        <!-- logo sticky bar -->
                        <sticky-logo></sticky-logo>
                    </div>
                </div>

                <div class="col-lg-9 col-md-8 sticky-right-col">
                    <div class="row header-search-wrapper vertical-align">
                        <!-- search sticky bar -->
                        <sticky-search></sticky-search>
                        <div class="col-xl-5 col-lg-3 col-md-4 wrp-ptc d-inline-flex">
                            <!-- promotion stikcy bar -->
                            <div class="promotion sticky-left-card">
                                <router-link
                                    class="nav-link reduceri-link"
                                    to="/promotie-seminee-promotii"
                                    ><span>OFERTE</span></router-link
                                >
                            </div>
                            <!-- telephone sticky bar -->
                            <div
                                class="phone header__square-pf header-left__common telephone sticky-left-card"
                            >
                                <a class="link" @click="showPhoneModal = true">
                                    <img width="23" height="23" class="fa-phone" src="@/assets/images/phone_mobile.svg" alt="Call" />
                                </a>
                                <phone-modal :show="showPhoneModal" @close="showPhoneModal = false"></phone-modal>
                            </div>
                            <!-- cart sticky bar -->
                            <div class="cart sticky-left-card">
                                <mini-cart-card class="sticky-cart"></mini-cart-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StickyMenu from "@/components/header/sticky/StickyMenu.vue";
import StickyLogo from "@/components/header/sticky/StickyLogo.vue";
import StickySearch from "@/components/header/sticky/StickySearch.vue";
import MiniCartCard from "@/components/header/minicart/MiniCartCard.vue";
import PhoneModal from "@/components/header/PhoneModal.vue";

export default {
    components: {
        StickyMenu,
        StickyLogo,
        StickySearch,
        MiniCartCard,
        PhoneModal,
    },

    data() {
        return {
            showPhoneModal: false,
        };
    },
};
</script>

<style>
.sticky-cart {
    height: 100%;
}

.wrp-ptc > div {
    flex: 1;
}

.wrp-ptc {
    height: 100%;
}

.vertical-align {
    display: flex;
    align-items: center;
}

.header-search-wrapper {
    background-color: #000;
    height: 54px;
}

.header-search-wrapper > div:not(:first-child) {
    padding: 0px;
}

.sticky-top-bar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 54px;
    background-color: rgba(0, 0, 0, 1);
    z-index: 16;
}

.sticky-top-bar > .container,
.sticky-top-bar .container > .row {
    height: 100%;
}

.sticky-left-col .row > div {
    text-align: center;
    padding: 0 0px;
    float: left;
}

.sticky-top-bar .header-search-wrapper {
    background-color: transparent;
}

.sticky-menu {
    position: relative;
    text-align: left;
}
.sticky-menu img {
    /* height: 55px; */
}
.sticky-menu .menu-item img {
    max-width: 45px;
    max-height: 45px;
}

.sticky-menu i {
    color: #fff;
}

.sticky-top-bar .sidebar-menu {
    top: 50px;
    display: none;
    left: 15px;
    z-index: 1;
}

.promotion {
    position: relative;
}

.telephone {
    position: relative;
}

.wrp-ptc .cart {
    float: left;
    position: relative;
}

.header-search-wrapper div a {
    text-decoration: none;
    border-radius: 0;
    font-family: Montserrat Bold;
}

.text-top-bar {
    float: left;
    line-height: 50px;
    padding: 0px 0 0 18.5px;
    font-family: Montserrat Regular;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
}

.simbol-top-bar {
    float: left;
    width: 53px;
    text-align: center;
}

.simbol-top-bar.percent,
.vertical-align .simbol-top-bar.percent {
    padding-top: 15px;
}

.header-details img,
.vertical-align .simbol-top-bar img {
    width: 16px;
}

.cart {
    background-color: #000;
}

.cos-icon {
    height: 50px;
    float: left;
    padding: 0px 6px;
}

.cos-text {
    width: 62px;
    display: block;
    color: #fff;
    float: left;
    padding-left: 3px;
    padding-top: 7px;
    font-family: Montserrat Regular;
    font-size: 12px;
    text-transform: none;
}

.item-number {
    -webkit-border-radius: 0.9em;
    -moz-border-radius: 0.9em;
    border-radius: 50%;
    font-weight: normal;
    font-size: 12px;
    padding: 0px 5px;
    position: absolute;
    color: #000;
    background: #fff;
    left: 25px;
    top: 4px;
}

.simbol-top-bar.percent,
.vertical-align .simbol-top-bar.percent {
    padding-top: 15px;
}

.cart .wrp-simbol i {
    background-color: #fff;
    color: #000;
}

@media (min-width: 1200px) {
    .cos-block,
    .cart {
        background-color: transparent;
    }

    .wrp-ptc .cart {
        width: 20%;
    }

    .cos-block {
        height: 54px;
        border-left: 1px solid rgba(151, 151, 151, 0.3);
    }

    .cart.cart-sticky .cos-block {
        width: 150px;
    }

    .item-number {
        top: 7px;
    }

    .cos-text {
        width: 86px;
        padding-top: 16px;
    }
}

@media (max-width: 1200px) {
    header span.cos-text {
        display: none;
    }

    header span.item-number {
        top: 2px;
        right: 4px;
        font-size: 14px;
        border-radius: 50%;
        padding: 0px 6px;
        background-color: #c0050f;
        color: #fff;
    }

    .wrp-ptc {
        display: flex;
        justify-content: flex-end;
    }

    .sticky-left-card {
        border-right: 1px solid rgba(40, 17, 7, 0.8);
    }

    .sticky-left-card .fa {
        font-size: 20px;
    }
}

@media (max-width: 1200px) and (min-width: 767px) {
    .text-top-bar {
        display: none;
    }
    .search-wrapper input {
        font-size: 16px;
        padding: 11px 13px;
        height: 100%;
    }
    .search-input {
        background-position: right;
    }
    .header-search-wrapper .fa.fa-percent,
    .header-search-wrapper .fa.fa-shopping-cart.cos-icon {
        font-size: 26px;
        padding: 14px;
    }

    .header-search-wrapper .fa.fa-shopping-cart.cos-icon {
        width: auto;
        height: auto;
    }
    .header-search-wrapper span.item-number {
        background-color: #c0050f;
        color: #fff;
        left: initial;
    }
    .cart.no-sticky {
        background-color: #fff;
        /*padding-top: 4px;*/
    }
    .header-details .fa.fa-shopping-cart.cos-icon {
        color: #000;
    }
    .cos-block {
        background-color: #fff;
    }
    .header-search-wrapper span.item-number {
        border: 2px solid #fff;
    }

    .header-search-wrapper.vertical-align .cart.cart-sticky .cos-block {
        width: auto;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {
    .simbol-top-bar {
        width: auto;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .wrp-ptc {
        width: auto;
    }
}

@media (max-width: 768px) {
    .sticky-top-bar {
        display: none;
    }
}
</style>
