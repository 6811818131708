<template>
  <div class="col-sm-8 col-md-8 col-lg-8 wrp-sticky-logo">
    <div class="sticky-bar-logo inline-logo">
      <router-link to="/">
        <img
          src="@/assets/images/logo_sticky_inline.png"
          alt="Pefoc.ro"
          class="sticky-image-container"
        />
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.sticky-bar-logo {
  background-color: #97040c;
  height: 54px;
}

.sticky-bar-logo img {
  width: 100px;
}

@media (min-width: 768px) {
  .sticky-bar-logo {
    padding: 12px 14px;
    float: left;
  }

  div.inline-logo img.sticky-image-container {
    width: 26px;
  }
}
</style>