<template>
  <div class="col-sm-4 col-md-4 col-lg-4 sticky-menu-bars">
    <div class="sticky-menu" @mouseenter="openMenuCategs" @mouseleave="closeMenuCategs">
      <img src="./menu-bars.png" alt="Menu bars" />
      <MenuStickyItems
        v-if="isMenuOpen"
        :categories="categories"
        class="sticky-menu-categs"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  data() {
    return {
      isMenuOpen: false,
    };
  },

  components: {
    MenuStickyItems: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "menustickyitems" */ "@/components/menu/MenuStickyItems.vue"
      )
    ),
  },

  methods: {
    openMenuCategs() {
      this.isMenuOpen = true;
    },

    closeMenuCategs() {
      this.isMenuOpen = false;
    },
  },
  computed: {
    categories() {
      return this.$store.getters["shop/bigMenu"];
    },
  },
  created() {
    if (!this.categories.length) {
      this.$store.dispatch("shop/getBigMenu");
    }
  },
};
</script>

<style scoped>
.sticky-menu-bars {
  max-width: 55px;
}

.sticky-menu-bars .sticky-menu i {
  display: block;
  font-size: 20px;
  position: relative;
  top: -10px;
}
</style>
